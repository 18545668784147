import React, { useState } from 'react';
import styled from 'styled-components';
import TeamGallery from './TeamGallery';
import { graphql, useStaticQuery } from 'gatsby';

import tedxImage from '../../images/backgrounds/tedx.png';

const memberData = [
  {
    name: 'David Boanuh',
    role: 'Cinematographer',
    description: () => (
      <>
        If we are the custodians of our own history we don’t have to worry about misrepresentation
        in media. I am a filmmaker who believes that the mitigation of African single story largely
        depends on the African film industry. I hope to be at the forefront of this revolution. TEDx
        Speaker
      </>
    ),
    social: {
      instagram: 'https://www.instagram.com/gospellifestyle/',
      twitter: 'https://twitter.com/Gospel_Official',
      linkedIn: 'https://www.linkedin.com/in/davidboanuh/',
      tedx: 'https://www.ted.com/talks/david_boanuh_the_future_of_film_making_in_africa_is_me',
    },
  },
  {
    name: 'Eric Boama',
    role: 'Photographer',
    description: () => (
      <>
        Every image created is a blank Cheque to tell stories untold. The value of each Cheque is
        dependent on the figure written (The story being told). While I have the opportunity to do
        this I wouldn’t stop until it is able to shift perspectives for the better, improve lives
        and have a generation at its best potential.
      </>
    ),

    social: {
      instagram: 'https://www.instagram.com/boama_official/',
      twitter: 'https://twitter.com/Boama_official',
      linkedIn: 'https://www.linkedin.com/in/eric-boama-b137aa153',
    },
  },
];

const TeamSection = () => {
  const [activeTeamMember, setActiveTeamMember] = useState(memberData[0]);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/members/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
              originalImg
            }
          }
        }
      }
    }
  `);

  const orderedData = data?.allFile?.nodes?.sort((a, b) => a.name - b.name);

  return (
    <StyledTeamContainer>
      <div className="image-container">
        <TeamGallery
          onClick={(index) => {
            const data = memberData[index];
            setActiveTeamMember(data);
          }}
          itemsPerRow={[2]}
          images={orderedData?.map((node, id) => {
            return {
              id,
              ...node.childImageSharp.fluid,
              caption: 'gallery-image',
            };
          })}
        />
      </div>

      <div className="profile-details">
        <div className="count">{activeTeamMember.name === 'David Boanuh' ? '01' : '02'} / 02</div>

        <div className="content">
          <div className="title">{activeTeamMember.role}</div>
          <div className="name">{activeTeamMember.name}</div>
          <div className="quote">{activeTeamMember.description()}</div>
        </div>

        <div>
          {activeTeamMember.social.tedx ? (
            <a
              href={activeTeamMember.social.tedx}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <img src={tedxImage} width={40} height={40} />
            </a>
          ) : (
            <div style={{ width: 47, height: 47 }} />
          )}
        </div>

        <div className="footer">
          {activeTeamMember.social.instagram && (
            <a
              href={activeTeamMember.social.instagram}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <svg viewBox="0 0 16 16" className style={{ width: '1em', height: '1em' }}>
                <path
                  d="M8 1.441c2.136 0 2.39.009 3.233.047.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.009 2.39-.047 3.233c-.036.78-.166 1.203-.276 1.485-.145.374-.318.64-.598.92-.28.28-.546.453-.92.598-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276-.374-.145-.64-.318-.92-.598s-.453-.546-.598-.92c-.11-.282-.24-.705-.276-1.485-.038-.844-.047-1.097-.047-3.233s.009-2.39.047-3.233c.036-.78.166-1.203.276-1.485.145-.374.318-.64.598-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.844-.038 1.097-.047 3.233-.047zM8 0C5.827 0 5.555.01 4.702.048 3.85.087 3.269.222 2.76.42c-.526.204-.973.478-1.417.923S.624 2.233.42 2.76C.222 3.269.087 3.85.048 4.702.01 5.555 0 5.827 0 8s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.204.526.478.973.923 1.417s.89.719 1.417.923c.509.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.445-.01 3.298-.048c.852-.039 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923s.719-.89.923-1.417c.198-.509.333-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.298c-.039-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417S13.767.624 13.24.42c-.509-.198-1.09-.333-1.942-.372C10.445.01 10.173 0 8 0zm0 3.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.775a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334zm5.23-6.937a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"
                  fill="white"
                />
              </svg>
            </a>
          )}

          {activeTeamMember.social.twitter && (
            <a
              href={activeTeamMember.social.twitter}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
            >
              <svg width={20} height={20} viewBox="0 0 512 512">
                <path
                  d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"
                  fill="white"
                />
              </svg>
            </a>
          )}

          {activeTeamMember.social.linkedIn && (
            <a
              href={activeTeamMember.social.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
            >
              <svg width={20} height={20} viewBox="0 0 512 512">
                <path
                  d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z"
                  fill="white"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    </StyledTeamContainer>
  );
};

const StyledTeamContainer = styled.section`
  margin: 0 10rem 10rem 10rem;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    margin: 0;
  }

  .image-container {
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin: 2rem;
    }
    div {
      height: 100%;
    }
  }

  .profile-details {
    margin-left: 3rem;
    background: ${({ theme }) => theme.colors.darkBlueLighter2x};
    width: 100%;
    flex: 1;
    color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
      margin: 2rem;
    }

    .content {
      margin: auto 0;

      .title {
        font-size: 0.9rem;
        text-transform: uppercase;
        margin: 1rem 0;

        color: ${({ theme }) => theme.colors.greyPrimary};
      }

      .name {
        font-size: 1.4rem;
        font-weight: 500;
      }

      .quote {
        margin: 1rem 0;
        font-size: 0.9rem;
        line-height: 25px;
      }
    }

    .footer {
      margin-top: 10px;
      a {
        margin-right: 10px;
      }
    }
  }
`;

export default TeamSection;
