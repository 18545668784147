import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Modal, ModalGateway } from 'react-images';
import { Box } from 'rebass';
import { Location, useLocation } from '@reach/router';

// components
import MobileNavbar from './MobileNavbar';
import { SectionHeader } from 'src/components/Photography';

import Logo from '../../../static/logo.png';

function CloseIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 512 512">
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="40px"
        d="M368 368L144 144"
      />
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="40px"
        d="M368 144L144 368"
      />
    </svg>
  );
}

const Image = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/menu/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return (
    <Box
      width={600}
      css={`
        display: inline-block;
        vertical-align: middle;
        transition: filter 0.3s;
        opacity: 1;
        visibility: visible;
        transition-property: transform;
        transition: 0.35s ease-out, opacity 0.35s ease-out, transform 0.35s ease-out;
        transform: translate3d(0, 0, 0);

        :hover {
          filter: brightness(87.5%);
        }
      `}
    >
      {data && data.allFile.nodes.length !== 0 ? (
        <Img
          fluid={
            data.allFile.nodes.find((file, index) => {
              return file.name === name;
            }).childImageSharp.fluid
          }
        />
      ) : (
        <p>No image</p>
      )}
    </Box>
  );
};

const Navbar = ({ dark }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const { pathname } = useLocation();

  const [showToggledMainMenu, toggleMainMenu] = useState(false);
  const [showPhotographyMenu, togglePhotographyMenu] = useState(false);

  const navbarRef = useRef();

  function addNavbarBackground() {
    const currentScroll = window.pageYOffset;

    switch (true) {
      case url.includes('/portfolio'):
        if (currentScroll >= 100) {
          navbarRef.current.classList.add('navbar-bg');
        } else navbarRef.current.classList.remove('navbar-bg');
        break;

      case url.includes('/contact'):
        navbarRef.current.classList.add('navbar-bg');
        break;

      default:
        if (currentScroll > window.innerHeight) {
          navbarRef.current.classList.add('navbar-bg');
        } else navbarRef.current.classList.remove('navbar-bg');
        break;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', addNavbarBackground);

    return () => window.removeEventListener('scroll', addNavbarBackground);
  });

  return (
    <>
      <StyledNavbar ref={navbarRef}>
        <NavItem to="/" dark={dark} active={pathname === '/'}>
          Home
        </NavItem>
        <NavItem to="/about" dark={dark} active={pathname === '/about'}>
          About
        </NavItem>
        <HoverableItem
          dark={dark}
          active={pathname.includes('/portfolio')}
          onMouseLeave={() => toggleMainMenu(false)}
          onMouseEnter={() => toggleMainMenu(true)}
        >
          <a className="nav-link">Portfolio</a>
          <SubMenu active={showToggledMainMenu}>
            <Link to="/portfolio/cinematography">
              <HoverableLink dark={dark} active={pathname.includes('/portfolio/cinematography')}>
                Cinematography
              </HoverableLink>
            </Link>

            <HoverableLink
              onClick={() => togglePhotographyMenu(true)}
              dark={dark}
              active={pathname.includes('/portfolio/photography')}
            >
              Photography
            </HoverableLink>
          </SubMenu>
        </HoverableItem>

        <ExternalLink href="https://medium.com/@davidboanuh" dark={dark}>
          Blog
        </ExternalLink>

        <NavItem to="/contact" dark={dark} active={pathname === '/contact'}>
          Contact
        </NavItem>

        {showPhotographyMenu && (
          <ModalGateway>
            <Modal>
              <PortfolioModal>
                <div className="container-sm">
                  <div className="header">
                    <Link to="/">
                      <img src={Logo} />
                    </Link>

                    <div
                      onClick={() => togglePhotographyMenu(false)}
                      tabIndex="1"
                      className="close-icon-container"
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  <SectionHeader sectionName="Photography" />
                </div>

                <div className="container">
                  <div className="nav-links">
                    <Link to="/portfolio/photography/ads" className="link">
                      <h3>ADS</h3>
                      <Image name="ads" />
                    </Link>

                    <Link to="/portfolio/photography/documentary" className="link">
                      <h3>Documentary</h3>
                      <Image name="documentaries" />
                    </Link>

                    <Link to="/portfolio/photography/events" className="link">
                      <h3>Events</h3>

                      <Image name="events" />
                    </Link>

                    <Link to="/portfolio/photography/portraits" className="link">
                      <h3>portraits</h3>
                      <Image name="portraits" />
                    </Link>
                  </div>
                </div>
              </PortfolioModal>
            </Modal>
          </ModalGateway>
        )}
      </StyledNavbar>

      <MobileNavbar />
    </>
  );
};

const PortfolioModal = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;

  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    margin-top: 10px;
  }

  .container-sm {
    max-width: 1240px;
    margin: 0 auto;
  }

  .header {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-icon-container {
      background-color: #edf2f7;
      cursor: pointer;
      display: block;
      padding: 5px;
      border-radius: 4px;
    }

    img {
      width: 50px;
      height: 50px;
      display: block;
    }

    svg {
      display: block;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    overflow-y: scroll;
  }

  .nav-links {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .link {
    cursor: pointer;
    h3 {
      font-size: 25px;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors.darkBluePrimary};
    }
  }
`;

const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  z-index: 101;
  transition: box-shadow 0.1s ease 0s;
  min-height: 50px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const HoverableItem = styled.p`
  color: ${({ theme, active, dark }) =>
    active ? theme.colors.goldPrimary : dark ? theme.colors.darkBluePrimary : 'white'} !important;
  color: '#2D3748';
  padding: 30px;
  cursor: 'pointer';
  position: 'relative';
  font-weight: 600;

  a {
    color: ${({ theme, active, dark }) =>
      active ? theme.colors.goldPrimary : dark ? theme.colors.darkBluePrimary : 'white'} !important;

    :hover {
      color: ${({ theme }) => theme.colors.goldPrimary} !important;
    }
  }
`;

const HoverableLink = styled.p`
  cursor: pointer;
  margin: 5px 0;
  text-align: center;
  color: ${({ theme, active, dark }) =>
    active ? theme.colors.goldPrimary : dark ? theme.colors.darkBluePrimary : 'white'} !important;

  :hover {
    color: ${({ theme }) => theme.colors.goldPrimary};
  }

  :visited {
    color: ${({ dark, theme }) => (dark ? theme.colors.darkBluePrimary : 'white')};
  }
`;

const SubMenu = styled.div`
  z-index: 111;
  position: absolute;
  margin-top: 15px;
  width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: -3rem;
  backdrop-filter: saturate(180%) blur(100px);
  transition: box-shadow 0.1s ease 0s !important;

  opacity: ${({ active }) => (active ? 1 : 0)};
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: left 0s linear, transform 0.2s ease-out, opacity 0.2s ease-out;
  -moz-transition: left 0s linear, transform 0.2s ease-out, opacity 0.2s ease-out;
  -ms-transition: left 0s linear, transform 0.2s ease-out, opacity 0.2s ease-out;
  -o-transition: left 0s linear, transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: left 0s linear, transform 0.2s ease-out, opacity 0.2s ease-out;
`;

const NavItem = styled(Link)`
  color: ${({ theme, active, dark }) =>
    active ? theme.colors.goldPrimary : dark ? theme.colors.darkBluePrimary : 'white'} !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 30px;
  font-weight: 600;
  font-size: 18px;

  :hover {
    color: ${({ theme }) => theme.colors.goldPrimary} !important;
  }
`;

const ExternalLink = styled.a`
  color: ${({ theme, active, dark }) =>
    active ? theme.colors.goldPrimary : dark ? theme.colors.darkBluePrimary : 'white'} !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 30px;
  font-size: 18px;
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.colors.goldPrimary};
  }
`;

export default Navbar;
