import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from 'rebass';
// import MouseFollow from './MouseFollow';
import { TweenLite, Elastic } from 'gsap';
import BackgroundImage from 'gatsby-background-image';

const Image = ({ name, width = [1, 1 / 2, 1 / 3, 1 / 4] }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/photography/home/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  //   let circle;

  //   if (typeof window !== 'undefined') {
  //     circle = document.getElementsByClassName('circle');
  //   }

  //   function moveCircle(e) {
  //     TweenLite.to(circle, 0.6, {
  //       css: {
  //         left: e.pageX,
  //         top: e.pageY,
  //         ease: Elastic.easeOut.config(1, 0.3),
  //       },
  //     });
  //   }

  //   function stickCircle(e) {
  //     TweenLite.set(circle, {
  //       css: {
  //         left: e.pageX,
  //         top: e.pageY,
  //       },
  //     });
  //   }

  return (
    <Box
      width={width}
      css={`
        transition: filter 0.3s;
        height: 100%;
        overflow: hidden;
        :hover {
          filter: brightness(87.5%);
        }
      `}
    >
      <>
        {data && data.allFile.nodes.length !== 0 && (
          <Img
            fluid={
              data.allFile.nodes.find((file) => {
                return file.name === name;
              }).childImageSharp.fluid
            }
          />
        )}
      </>
    </Box>
  );
};

export default Image;
