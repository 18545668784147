import styled from 'styled-components';

const ShootPage = styled.div`
  background-color: ${({ theme, bg }) => theme.colors[bg]};
  min-height: 100vh;
  padding-bottom: 10rem;

  .write-up {
    margin-top: 2.5rem;
    text-align: left;

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
`;

ShootPage.defaultProps = {
  bg: 'white',
};

export default ShootPage;
