import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from 'rebass';
import MouseFollow from './MouseFollow';
import { TweenLite, Elastic } from 'gsap';

const Image = ({ imageStyles, imageContainerStyles, name, width = [1, 1 / 2, 1 / 3, 1 / 4] }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/miscellaneous/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  let circle;

  // if (typeof window !== 'undefined') {
  //   circle = document.getElementsByClassName('circle');
  // }

  // function moveCircle(e) {
  //   TweenLite.to(circle, 0.6, {
  //     css: {
  //       left: e.pageX,
  //       top: e.pageY,
  //       ease: Elastic.easeOut.config(1, 0.3),
  //     },
  //   });
  // }

  // function stickCircle(e) {
  //   TweenLite.set(circle, {
  //     css: {
  //       left: e.pageX,
  //       top: e.pageY,
  //     },
  //   });
  // }

  return (
    <Box
      width={width}
      style={imageContainerStyles}
      css={`
        display: inline-block;
        vertical-align: middle;
        transition: filter 0.3s;
        opacity: 1;
        visibility: visible;
        transition-property: transform;
        transition: 0.35s ease-out, opacity 0.35s ease-out, transform 0.35s ease-out;
        transform: translate3d(0, 0, 0);
        :hover {
          filter: brightness(87.5%);
        }
      `}
    >
      {data && data.allFile.nodes.length !== 0 ? (
        <Img
          style={imageStyles}
          fluid={
            data.allFile.nodes.find((file, index) => {
              return file.name === name;
            }).childImageSharp.fluid
          }
        />
      ) : (
        <p>No image</p>
      )}
    </Box>
  );
};

export default Image;
