import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '../../../static/logo.png';

const Footer = () => {
  return (
    <StyledFooterContainer>
      <div className="list">
        <div className="list-header">Press</div>
        <ul>
          <a href="https://www.bbc.co.uk/programmes/p08mqqnr">BBC</a>
          <a href="https://www.youtube.com/watch?v=dxzZuUkYkjA&t=84s">CitiTV</a>
          {/* <a>USAID</a>
            <a>Forbes</a> */}
          <a href="https://www.facebook.com/3fm927/videos/635902530365693/">3FM</a>
        </ul>
      </div>

      <div className="logo">
        <img src={Logo} />
      </div>

      <div className="social-icons">
        <a
          href="https://www.linkedin.com/company/beautiful-stories-studios/"
          target="_blank"
          rel="noopener noreferrer"
          className="styles-module--social-button--2y5Er"
          aria-label="linkedin"
        >
          <svg viewBox="0 0 17 16" className style={{ width: '1em', height: '1em' }}>
            <path
              d="M15.3 0H.7C.3 0 0 .3 0 .7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V.7c-.1-.4-.4-.7-.8-.7zM4.7 13.6H2.4V6h2.4v7.6zM3.6 5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4S5 2.8 5 3.6C4.9 4.3 4.3 5 3.6 5zm10 8.6h-2.4V9.9c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2z"
              fill="#0077b5"
            />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/beautifulstories.projects/"
          target="_blank"
          rel="noopener noreferrer"
          className="styles-module--social-button--2y5Er"
          aria-label="instagram"
        >
          <svg viewBox="0 0 16 16" className style={{ width: '1em', height: '1em' }}>
            <path
              d="M8 1.441c2.136 0 2.39.009 3.233.047.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.009 2.39-.047 3.233c-.036.78-.166 1.203-.276 1.485-.145.374-.318.64-.598.92-.28.28-.546.453-.92.598-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276-.374-.145-.64-.318-.92-.598s-.453-.546-.598-.92c-.11-.282-.24-.705-.276-1.485-.038-.844-.047-1.097-.047-3.233s.009-2.39.047-3.233c.036-.78.166-1.203.276-1.485.145-.374.318-.64.598-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.844-.038 1.097-.047 3.233-.047zM8 0C5.827 0 5.555.01 4.702.048 3.85.087 3.269.222 2.76.42c-.526.204-.973.478-1.417.923S.624 2.233.42 2.76C.222 3.269.087 3.85.048 4.702.01 5.555 0 5.827 0 8s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.204.526.478.973.923 1.417s.89.719 1.417.923c.509.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.445-.01 3.298-.048c.852-.039 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923s.719-.89.923-1.417c.198-.509.333-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.298c-.039-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417S13.767.624 13.24.42c-.509-.198-1.09-.333-1.942-.372C10.445.01 10.173 0 8 0zm0 3.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.775a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334zm5.23-6.937a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"
              fill="#e1306c"
            />
          </svg>
        </a>

        <a
          href="https://www.youtube.com/channel/UCIJctiC8KbeoNIBREf4Hi8g?view_as=subscriber"
          target="_blank"
          rel="noopener noreferrer"
          className="styles-module--social-button--2y5Er"
          aria-label="instagram"
        >
          <svg
            height="512pt"
            viewBox="0 -77 512.00213 512"
            width="512pt"
            style={{ width: '1em', height: '1em' }}
          >
            <path
              d="M501.453 56.094c-5.902-21.934-23.195-39.223-45.125-45.13C416.262 0 255.996 0 255.996 0S95.734 0 55.668 10.547C34.16 16.449 16.445 34.164 10.543 56.094 0 96.156 0 179.242 0 179.242s0 83.504 10.543 123.149c5.906 21.93 23.195 39.222 45.129 45.129C96.156 358.484 256 358.484 256 358.484s160.262 0 200.328-10.546c21.934-5.903 39.223-23.196 45.129-45.125C512 262.745 512 179.663 512 179.663s.422-83.508-10.547-123.57zm0 0"
              fill="red"
            />
            <path d="M204.969 256l133.27-76.758-133.27-76.758zm0 0" fill="#fff" />
          </svg>
        </a>

        <a
          href="https://web.facebook.com/Beautiful-Stories-Studios-106473947831887?_rdc=1&_rdr"
          target="_blank"
          rel="noopener noreferrer"
          className="styles-module--social-button--2y5Er"
          aria-label="instagram"
        >
          <svg
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            style={{ width: '1em', height: '1em' }}
          >
            <path
              d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z"
              fill="#1976d2"
            />
            <path
              d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z"
              fill="#fafafa"
            />
          </svg>
        </a>
      </div>
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled.footer`
  display: flex;
  padding: 8rem 15rem 5rem 15rem;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding: 1rem;
    margin-top: 10rem;
    height: 100%;
    margin-bottom: 0;
  }

  .pattern {
    margin-top: -5rem;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }

  .list {
    .list-header {
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-left: 0;

      a {
        margin: 0.1rem 0;
        font-size: 1rem;
        width: 100%;
        color: ${({ theme }) => theme.colors.darkBluePrimary};

        :hover {
          color: ${({ theme }) => theme.colors.goldPrimary};
        }
      }
    }
  }

  .social-icons {
    a {
      margin: 0 10px;
    }
  }

  .logo {
    margin: 0 auto;
    img {
      width: 80px;
      height: 80px;
    }
  }
`;

export default Footer;
