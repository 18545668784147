import React from 'react';
import styled from 'styled-components';

const Button = ({ style, onClick, background, children, ...rest }) => {
  return (
    <StyledButton {...rest} style={style} onClick={() => onClick()} background={background}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background: ${({ theme, background }) => theme.colors[background]};
  color: ${({ theme, color }) => theme.colors[color]};
  width: 200px;
  font-size: 1rem;
  height: 55px;
  font-weight: 500;
  border: none;
`;

Button.defaultProps = {
  background: 'darkBluePrimary',
  color: 'white',
  onClick: () => {},
};

export default Button;
