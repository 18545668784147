import React, { useReducer } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '../../../static/logo.png';

const MobileNavbar = ({ dark }) => {
  const [active, setActive] = useReducer((toggled) => !toggled, false);

  return (
    <>
      <StyledMobileNav>
        <div className="header">
          <Link to="/">
            <img src={Logo} width="50px" />
          </Link>
          <button className="icon" onClick={setActive}>
            <svg width={30} height={30} viewBox="0 0 513 513">
              <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth="32px"
                d="M80 160L432 160"
              />
              <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth="32px"
                d="M80 256L432 256"
              />
              <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth="32px"
                d="M80 352L432 352"
              />
            </svg>
          </button>
        </div>
        <div className={active ? 'modal-view' : 'close'}>
          <div className="header">
            <Link to="/">
              <img src={Logo} width="50px" />
            </Link>
            <button className="icon" onClick={setActive}>
              <svg width={30} height={30} viewBox="0 0 513 513">
                <path
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32px"
                  d="M368 368L144 144"
                />
                <path
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32px"
                  d="M368 144L144 368"
                />
              </svg>
            </button>
          </div>

          <div className="links">
            <Link to="/">
              <span className="header">Home</span>
            </Link>

            <Link to="/about">
              <span className="header"> About</span>
            </Link>

            <Link to="/portfolio/photography">
              <span className="header"> Portfolio</span>
            </Link>

            <Link to="/portfolio/cinematography">
              <span className="header"> Cinematography</span>
            </Link>

            {/* <Link to="/contact">
              <span className="header"> Contact</span>
            </Link>

            <Link to="/blog">
              <span className="header">Blog</span>
            </Link> */}
          </div>
        </div>
      </StyledMobileNav>
    </>
  );
};

const StyledMobileNav = styled.nav`
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
  background: white;
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
  }

  .close {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .icon {
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      border: none;
      background-color: white;
    }
  }

  .modal-view {
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    background: ${({ theme }) => theme.colors.goldPrimary};
    padding: 15px 20px;
  }

  .links {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    a {
      margin-bottom: 2rem;

      .header {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
`;

export default MobileNavbar;
