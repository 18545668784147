import React from 'react';
import styled from 'styled-components';

import background from 'src/images/backgrounds/bottom-pattern.png';

const Banner = () => {
  return (
    <StyledBanner>
      <div className="wrapper">
        <h2>Our best work is our next work. Let’s tell your beautiful story.</h2>
        <div className="footer">
          <div className="right">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@beautifulstories.co?subject=Enquiry"
            >
              Book us
            </a>
          </div>
        </div>
      </div>
      <img src={background} />
    </StyledBanner>
  );
};

const StyledBanner = styled.section`
  background-color: ${({ theme }) => theme.colors.goldPrimary};
  color: black;

  .wrapper {
    padding: 10rem 10rem;
  }

  img {
    transform: rotate(180deg);
    margin-bottom: -10px;
  }

  @media only screen and (max-width: 600px) {
    padding: 5rem 1rem;
  }

  h2 {
    font-size: 5rem;
    font-weight: 500;
    color: black;
    text-align: center;
    font-family: 'Asul';

    @media only screen and (max-width: 600px) {
      font-size: 2.5rem;
    }
  }

  a {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme, color }) => theme.colors[color]};
    width: 200px;
    font-size: 1rem;
    height: 55px;
    font-weight: 500;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .footer {
    margin-top: 4rem;
    display: flex;
    justify-content: center;

    .right {
      display: flex;
    }
  }
`;

export default Banner;
