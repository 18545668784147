import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ className, children, name }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { regex: "/BackgroundImage/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1240) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        all: allFile(filter: { relativePath: { regex: "/backgrounds/" } }) {
          nodes {
            name
            childImageSharp {
              fluid(maxWidth: 1240) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.all.nodes.find((node) => node.name === name);
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData.childImageSharp.fluid}
          backgroundColor={`#fff`}
          color="black"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </div>
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

export default StyledBackgroundSection;
