import React from 'react';
import Img from 'gatsby-image';
import { Box } from 'rebass';

const TeamGallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints = [1], onClick }) => {
  return (
    <Box
      className="teamGallery-container"
      css={`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 20px;
      `}
    >
      {images.map(({ id, caption, ...fluid }, i) => (
        <Box
          className="team-member"
          onMouseEnter={() => onClick(id)}
          // onClick={() => onClick(id)}
          key={id}
          width={300}
          height={550}
          css={`
            display: inline-block;
            vertical-align: middle;
            transition: filter 0.3s;
            overflow: hidden;
            :hover {
              filter: brightness(87.5%);
            }
          `}
        >
          <Img fluid={fluid} />
        </Box>
      ))}
    </Box>
  );
};

export default TeamGallery;
