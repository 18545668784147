import Img from 'gatsby-image';
import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Box, Link } from 'rebass';
import { chunk, sum, carouselFormatters } from 'src/utils/';

const Gallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints = [1] }) => {
  const aspectRatios = images.map((image) => image.aspectRatio);

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map((item) => {
    return chunk(aspectRatios, item).map((rowAspectRatios) => {
      return sum(rowAspectRatios);
    });
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const closeModal = () => setModalIsOpen(false);

  const openModal = (imageIndex) => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  return (
    <Box>
      {images.map((image, i) => (
        <Link
          key={image.id}
          href={image.originalImg}
          onClick={(e) => {
            e.preventDefault();
            openModal(i);
          }}
        >
          <Box
            key={image.id}
            width={rowAspectRatioSumsByBreakpoints.map((rowAspectRatioSums, j) => {
              const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
              const rowAspectRatioSum = rowAspectRatioSums[rowIndex];
              return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`;
            })}
            css={`
              display: inline-block;
              vertical-align: middle;
              transition: filter 0.3s;
              :hover {
                filter: brightness(87.5%);
              }
            `}
          >
            <Img fluid={image} />
          </Box>
        </Link>
      ))}
      {/* 
      {ModalGateway && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map(({ originalImg, caption }) => ({
                  source: originalImg,
                  caption,
                }))}
                currentIndex={modalCurrentIndex}
                formatters={carouselFormatters}
                components={{ FooterCount: () => null }}
              />
            </Modal>
          )}
        </ModalGateway>
      )} */}
    </Box>
  );
};

export default Gallery;
