import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from 'rebass';

const Image = ({ name, width = [1, 1 / 2, 1 / 3, 1 / 4] }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/cinematography-images/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <Box
        width={width}
        css={`
          display: inline-block;
          vertical-align: middle;

          transition: filter 0.3s;
          opacity: 1;
          visibility: visible;
          transition-property: transform;
          transition: 0.35s ease-out, opacity 0.35s ease-out, transform 0.35s ease-out;
          transform: translate3d(0, 0, 0);
          :hover {
            filter: brightness(87.5%);
          }
        `}
      >
        {data && data.allFile.nodes.length !== 0 ? (
          <Img
            fluid={
              data.allFile.nodes.find((file, index) => {
                return file.name === name;
              }).childImageSharp.fluid
            }
          />
        ) : (
          <p>No image</p>
        )}
      </Box>
    </div>
  );
};

export default Image;
