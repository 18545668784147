const carouselFormatters = {
  getAltText: ({ data, index }) => data.caption || `${index + 1}. kép`,

  getNextLabel: ({ currentIndex, views }) =>
    `Mutasd a(z) ${currentIndex + 2}. képet a(z) ${views.length} közül`,
  getPrevLabel: ({ currentIndex, views }) =>
    `Mutasd a(z) ${currentIndex}. képet a(z) ${views.length} közül`,

  getNextTitle: () => 'Next Title',
  getPrevTitle: () => 'Previous Title',

  getCloseLabel: () => 'CloseLabel',
  getFullscreenLabel: ({ isFullscreen }) =>
    isFullscreen ? 'FullScreen Image' : 'NonFullScreen Image',
};

export default carouselFormatters;
