import React from 'react';
import styled from 'styled-components';

const Bubble = (props) => {
  return (
    <BubbleWrapper>
      <p>{props.wording}</p>
    </BubbleWrapper>
  );
};

export default Bubble;

const BubbleWrapper = styled.button`
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 4rem;
  padding: 3rem 3rem 1rem 3rem;
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => `0,028rem solid ${props.theme.colors.greyPrimary}`};
  border-radius: 10px;
  shadow: none;
  padding-top: 20px;
`;
