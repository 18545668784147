import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'magmel';

// components
import SEO from 'src/components/seo';

const SectionHeader = ({ sectionName, bg = 'white' }) => {
  return (
    <>
      <SEO title={capitalize(sectionName)} />
      <StyledSectionHeader bg={bg}>
        <hr />
        <h2>{sectionName}</h2>
      </StyledSectionHeader>
    </>
  );
};

const StyledSectionHeader = styled.div`
  margin: 50px 0;
  font-size: 2rem;
  position: relative;
  hr {
    height: 2px;
    background-color: ${({ theme }) => theme.colors.darkBluePrimary};
  }

  h2 {
    background-color: ${({ bg, theme }) => theme.colors[bg]};
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.darkBluePrimary};
    text-transform: uppercase;

    @media only screen and (max-width: 600px) {
      font-size: 1.4rem;
      padding: 0 5px;
      text-align: center;
    }
  }
`;

export default SectionHeader;
