import React from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const Map = () => (
  <div className="inner">
    <iframe
      src="https://maps.google.com/maps?hl=en&amp;q=Beautiful%20Stories%20Studios%20LLC,%20No%206%20Market%20Cl,%20Ogbodjo+(Beautiful%20Stories%20Studios%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      frameBorder="0"
      title="Map"
    />
  </div>
);

const Footer = () => {
  const [sendingMail, toggleSendingMail] = React.useState(false);

  function handleSumbit(e) {
    e.preventDefault();

    toggleSendingMail(true);
    emailjs.sendForm('zoho', 'template_STQO5N9Z', e.target, 'user_FNNgzZpV4aPm6fiOdy7IS').then(
      (result) => {
        console.log({ result });
        toggleSendingMail(false);
        document && document.getElementById('contact-form').reset();
      },
      (error) => {
        console.log(error);
        toggleSendingMail(false);
      },
    );
  }
  return (
    <div style={{ position: 'relative', marginTop: 80 }}>
      <StyledPortfolioFooter>
        <div className="wrapper">
          <div className="left">
            <div className="inner">
              <div>
                <h2 className="header">Hello there, how can we help you?</h2>
                <div className="input-div">
                  <form onSubmit={handleSumbit} id="contact-form">
                    <input type="text" placeholder="Name" disabled={sendingMail} name="user_name" />
                    <input
                      type="email"
                      placeholder="Email"
                      disabled={sendingMail}
                      name="user_email"
                    />
                    <textarea
                      type="text"
                      placeholder="Message"
                      style={{
                        color: 'white',
                      }}
                      disabled={sendingMail}
                      name="user_message"
                    />
                    <button type="submit" disabled={sendingMail}>
                      {sendingMail ? 'Sending...' : 'Submit'}
                    </button>
                  </form>
                </div>
              </div>

              <div className="footer">
                <div className="office">
                  <h3>OFFICE</h3>
                  <p>Ogbodjo</p>
                  <p>No 6 Market Cl, Ogbodjo</p>
                </div>

                <div className="contact">
                  <h3>CONTACT US</h3>
                  <p>info@beautifulstories.co</p>
                  <p>+233 54 641 2949</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Map />
          </div>
        </div>
      </StyledPortfolioFooter>
    </div>
  );
};

const StyledPortfolioFooter = styled.header`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    align-items: stretch;
    flex: 1;

    .left {
      flex-basis: 50%;
      padding: 8rem 6rem;
      background-color: #0f0f0f;
      color: white;

      button {
        background: ${({ theme }) => theme.colors.goldPrimary};
        color: black;
        width: 200px;
        font-size: 1rem;
        height: 55px;
        font-weight: 500;
        margin-top: 20px;
        border: none;
      }

      .inner {
        display: flex;
        flex: 1;
        flex-direction: column;

        .footer {
          margin-top: 4rem;
          display: flex;
          justify-content: space-between;

          h3 {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }

        input,
        textarea {
          display: block;
          width: 100%;
          background-color: transparent;
          color: ${({ theme }) => theme.colors.greyPrimary};
          border: none;
          border-bottom: 0.01rem solid ${({ theme }) => theme.colors.greyPrimary};
          margin-bottom: 1rem;
          padding: 1rem 0;

          ::placeholder {
            color: white;
          }
        }

        .input-div {
          margin-top: 2rem;
        }

        .header {
          font-size: 2.5rem;
          line-height: 50px;
          color: ${({ theme }) => theme.colors.white};
        }

        input {
          color: ${({ theme }) => theme.colors.white};
        }

        .subtext {
          color: ${({ theme }) => theme.colors.darkBlueLighter3x};
          margin: 2rem 0;
          line-height: 2rem;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }

    .right {
      flex-basis: 50%;
      background-color: #e5e5e5;
      position: relative;
      overflow: hidden;

      .inner {
        position: relative;
        padding-bottom: 100%;
        object-fit: contain;
      }
    }
  }
`;

export default Footer;
